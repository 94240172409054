import { NGXLogger } from 'ngx-logger';
import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { UntypedFormControl, NgForm, FormGroupDirective } from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';
import { ChangeDetectorRef } from '@angular/core';
import { Observable, Subject, of } from 'rxjs';
import { switchMap, debounceTime, tap } from 'rxjs/operators';
import { MatSnackBarRef, SimpleSnackBar } from '@angular/material/snack-bar';
import { ModalWindowService, BannerButton, NotificationBannerService, NotificationTypes } from '@bci-web-core/core';
import { TranslateService } from '@ngx-translate/core';
import { saveAs } from 'file-saver';

import { MessageService, ToastTypes } from '@rexroth/core';
import { ProductVariantService } from '../_services/product-variant.service';
import { NavigationService } from '../_services/navigation.service';
import { ModuleData } from '../_services/module-data';
import { ParameterService } from '../_services/parameter.service';
import { ModuleDataService } from '../_services/module-data.service';
import { JobService } from '../_services/job.service';
import { OperationHelpComponent } from './../operation-help/operation-help.component';
import { DEFINITIONS } from './../definitions';
import { JobInfoDTO } from '../_models/job-info-dto';
import { IProductVariant } from '../_models/product-variant';
import { ProductVariant } from '../_models/product-variant';
import { DataPortalIFrameService} from '../_services/data-portal-iframe.service';

@Component({
  selector: 'app-generator',
  templateUrl: './generator.component.html',
  styleUrls: ['./generator.component.scss']
})

export class GeneratorComponent implements OnInit, OnDestroy {

  // access to form input field (typecode or materialnumber)
  @ViewChild('typeIdInputPartVar') public userTypeIdInputPartVar: NgForm;

  // state texts
  private generationInWorkText: string;
  private generateSuccessText: string;
  private generateErrorText: string;
  private typeCodeErrorInvalidText: string;
  private downloadErrorText: string;
  private downloadErrorSingleFileNotFoundText: string;
  private querySwitchGeneratorText: string;
  private querySwitchGeneratorBtnText: string;

  public matInfoSnackBarRef: MatSnackBarRef<SimpleSnackBar>;
  public matResultSnackBarRef: MatSnackBarRef<SimpleSnackBar>;

  // generator placeholder texts
  public typeCodePlaceholder: string;
  private typeCodePlaceholderText: string;
  private typeCodePlaceholderAutocompleteText: string;

  // type code variables
  public typeCodeRootPart: string = '';
  public typeCodeInputPart: string;
  public typeCodeComplete: string;
  public typeCodeForJobServerRequest: string;
  public typeCodeIsValid = false;
  public phcodes: string[];

  // job variables
  private compomentIsVisible = false;
  public jobActive = false;
  public jobSuccess = false;
  public jobError = false;
  public jobStatus = '_JobStatus';
  public jobMessage = '';
  public alertClass = 'alert-info';
  public downloads = [];
  public showDownloads = false;
  private actJobId;

  // autocomplete modes
  public autocompleteTypeCodeMode = true;
  public materialNumber = undefined;
  public autocompTypeList$: Observable<ProductVariant[]>;
  public autocompTypeListCurrent: IProductVariant[];
  public typeCodeInputPart$: Subject<string> = new Subject<string>();

  moduleData: ModuleData;

  public currentGeneratorLanguage: string;
  public matcher = new MyErrorStateMatcher();
  public downloadInProcess = false;
  public notificationBannerId: string;
  private notificationBannerModuleId: ModuleData;


  constructor(private logger: NGXLogger,
              public messageService: MessageService,
              public notificationBannerService: NotificationBannerService,
              public parService: ParameterService,
              public moduleDataService: ModuleDataService,
              public jobService: JobService,
              private navigationService: NavigationService,
              private productVariantService: ProductVariantService,
              private cd: ChangeDetectorRef,
              public translate: TranslateService,
              private modalWindowService: ModalWindowService,
              private iFrameService: DataPortalIFrameService) {

    this.logger.info('(generator.component) => constructor()');

    // init language
    if (this.currentGeneratorLanguage !== this.parService.language) {
      translate.use(this.parService.language);
      this.currentGeneratorLanguage = parService.language;
    }
    // read strings
    this.readTexts();
  }

  async ngOnInit() {
    this.compomentIsVisible = true;
    this.navigationService.initQueryParams();

    // autocomplete
    this.initAutoComplete();

    // validate typeCode (redirect with default values in case of invalid type code)
    if (!DEFINITIONS.regexBosTypeCode.test(this.parService.rootPartNr)) {
      await this.redirectToOverviewPage();
      return;
    }

    // query module data
    this.initComponent();

    // update sidebar menu (order tracker menu item)
    if (this.parService.rootPartNr) {
      this.navigationService.updateRouteParameters({ rootPartNr: this.parService.rootPartNr });
    }

    // try to attach to last running job
    this.jobService.attachClientToJob(this.typeCodeForJobServerRequest,
    (response) => {
      this.handleResponse(response);
    });

    this.handleIFrameDataPortal();
  }

  async handleIFrameDataPortal() {
    this.logger.info('(generator.component) handleIFrameDataPortal() => Await for Promise!');
    await this.iFrameService.handleDataPortalCall();
  }

  ngOnDestroy(): void {
    this.compomentIsVisible = false;
    this.disposeInfoSnackBar();
    this.closeChangeGeneratorBanner();
    this.jobService.detachClient();
    this.typeCodeInputPart$.unsubscribe();
  }

  private readTexts() {
    this.translate.get([
      '_GenerationInWork',
      '_GenerateSuccess',
      '_GenerateError',
      '_InvalidTypeCodeFormat',
      '_TypeCodePlaceholder',
      '_TypeCodePlaceholderAutocomplete',
      '_DownloadError',
      '_DownloadErrorSingleFileNotFound',
      '_QuerySwitchGenerator',
      '_QuerySwitchGeneratorBtn'])
      .subscribe(
        data => {
          this.generationInWorkText = data._GenerationInWork;
          this.generateSuccessText = data._GenerateSuccess;
          this.generateErrorText = data._GenerateError;
          this.typeCodeErrorInvalidText = data._InvalidTypeCodeFormat;
          this.typeCodePlaceholderText = data._TypeCodePlaceholder;
          this.typeCodePlaceholderAutocompleteText = data._TypeCodePlaceholderAutocomplete;
          this.downloadErrorText = data._DownloadError;
          this.downloadErrorSingleFileNotFoundText = data._DownloadErrorSingleFileNotFound;
          this.querySwitchGeneratorText = data._QuerySwitchGenerator;
          this.querySwitchGeneratorBtnText = data._QuerySwitchGeneratorBtn;
        });
  }

  private initComponent(): void {
    if (!this.moduleData) {
      // get module data from server
      this.moduleDataService.getModuleData(this.parService.calledTypeCode).subscribe( (data) => {
          this.moduleData = data;
          if (!this.moduleData.moduleId) {
            this.moduleData = undefined;
            this.redirectToOverviewPage();
            return;
          }
          this.initModuleData();
          this.initJobVariables();
        });
      } else {
        this.initModuleData();
        this.initJobVariables();
      }
  }

  private initModuleData(): void {
    this.typeCodeRootPart = this.moduleData.moduleId;
    this.phcodes = this.moduleData.phcodes;
    this.typeCodeInputPart = (this.parService.calledTypeCode.substring(this.typeCodeRootPart.length));
    this.checkInput();
    this.setMaterialNumberByTypecode(this.typeCodeComplete);
  }

  private initJobVariables(): void {
    this.typeCodeIsValid = true;
    this.jobActive = false;
    this.jobSuccess = false;
    this.jobError = false;
    this.jobStatus = '_JobStatus';
    this.jobMessage = '';
    this.alertClass = 'alert-info';
    this.downloads = [
      // // Test data
      // {
      //   title: 'COREX-C-X3-11-ANNN-21.01-01RS-NN-NN.edz', 
      //   links: { file: { href: 'https://ecad-configuration-dev.apps.de1.bosch-iot-cloud.com/usw.edz' } }
      // },
      // {
      //   title: 'COREX-C-X3-11-ANNN-21.01-01RS-NN-NN.pdf',
      //   links: { file: { href: 'https://ecad-configuration-dev.apps.de1.bosch-iot-cloud.com/usw.pdf' } }
      // }
    ];
    this.showDownloads = this.parService.downloads;
  }

  private async redirectToOverviewPage() {
    await this.navigationService.navigate('/overview');
  }

  public openLink(controlId: string, newTab: boolean): void {
    const cntrl = document.getElementById(controlId);
    if (cntrl !== null) {
      const href = cntrl.getAttribute('href');
      if (newTab) {
        window.open(href, '_blank');           // new tab
      } else {
        window.open(href, '_self');            // current tab
      }
    }
  }

  private openChangeGeneratorBanner(moduleData: ModuleData): string {
    const buttons: BannerButton[] = [];
    buttons.push({
      text: this.querySwitchGeneratorBtnText,
      link: '',
      clickHandler: () => { 
        // switch generator
        this.navigationService.navigate('/generator',{ 
          moduleId: this.typeCodeInputPart, 
          replaceInHistory: false, 
          reloadRoute: true });
      }
    });
    let description = this.querySwitchGeneratorText.replace('{0}', moduleData.moduleId).replace('{1}', moduleData.title);
    return this.notificationBannerService.showBanner(description, NotificationTypes.Information, buttons, false);
  }

  private closeChangeGeneratorBanner() {
    if( this.notificationBannerId) {
      this.notificationBannerService.hideBanner( this.notificationBannerId);
      this.notificationBannerId = undefined;
    }
  }

  private checkForChangeGeneratorBanner() {
    //check for alternative generator
    if( this.typeCodeInputPart.length > 0) {
      this.moduleDataService.getModuleData(this.typeCodeInputPart.toUpperCase()).subscribe( (data) => {
        if( data.title && data.title !== this.moduleData.title) {
          if((!this.notificationBannerId) || (data.title !== this.notificationBannerModuleId.title)) {
            this.logger.info('(generator.component) Alternative Generator found: ' + data.title);
            this.closeChangeGeneratorBanner();
            this.notificationBannerId = this.openChangeGeneratorBanner(data);
            this.notificationBannerModuleId = data;
          }
        } else {
          this.logger.info('(generator.component) No alternative Generator found.');
          this.closeChangeGeneratorBanner();
        }
      });
    } else {
      this.closeChangeGeneratorBanner();
    }
  }

  public checkInput(): void {
    this.materialNumber = '';
    this.cd.detectChanges();   // Important: overtake initial value (e.g. from paste)

    if (typeof this.typeCodeInputPart === 'string') {
      //replace none breaking spaces by normal spaces
      this.typeCodeInputPart = this.replaceNoneBreakingSpace(this.typeCodeInputPart);

      this.typeCodeInputPart = this.typeCodeInputPart.toUpperCase().replace(this.typeCodeRootPart, '');
      this.typeCodeComplete = this.typeCodeRootPart + this.typeCodeInputPart;
      this.typeCodeForJobServerRequest = this.typeCodeComplete.replace(/\//g, '%2F');
      this.setMaterialNumberByTypecode(this.typeCodeComplete);

      //check for alternative generator
      this.checkForChangeGeneratorBanner();
    }  

    // handle autocomplete
    if (this.typeCodeInputPart$.observers) {
      this.typeCodeInputPart$.next(this.typeCodeInputPart);
    }
  }

  private replaceNoneBreakingSpace(str) {
    const re = new RegExp(String.fromCharCode(160), "g");
    return str.replace(re, " ");
  }

  public onTypeCodeInputPartClear() {
    this.typeCodeInputPart = '';
    this.materialNumber = '';
    this.checkInput();
  }

  public onSendTypeCode(): void {
    //keyboard Return => check for cancellation
    if( this.isBtnGenerateDisabled()) {
      return;
    }

    // synchronize device data with autocomplete data
    this.syncDeviceDataWithAutocompleteData();

    // required: set values in case of autocomplete selection
    this.typeCodeInputPart = this.getTypeCodeInputPart(this.typeCodeInputPart);
    this.typeCodeComplete = this.typeCodeRootPart + this.typeCodeInputPart;
    this.typeCodeForJobServerRequest = this.typeCodeComplete.replace(/\//g, '%2F');

    if (this.jobActive) {
      return;
    }
    this.initJobVariables();

    // reload page to put typeCode in query params
    this.navigationService.navigate('/generator', {
      moduleId: this.typeCodeComplete,
      replaceInHistory: true,
      reloadRoute: false });

    // save session params
    sessionStorage.setItem('ecad.generator.TypeCode', this.typeCodeComplete);
    sessionStorage.setItem('ecad.generator.MaterialNumber', this.materialNumber);

    // update sidebar menu with new generator typeCode
    this.navigationService.updateRouteParameters({ rootPartNr: 'BOS.' + this.typeCodeComplete + '**' });
    if (this.typeCodeIsValid) {
      this.resultNotificationEnabled = true;
      this.jobService.sendJob(this.typeCodeForJobServerRequest, this.materialNumber,
        (response) => {
          if (response.isError === true) {
            this.handleResponse(response);
          } else {
            const jobInfoDTO = new JobInfoDTO(response);
            this.handleResponse(jobInfoDTO);
          }
        });
    }
  }

  private handleError(response: any): void {
    this.jobError = true;
    this.jobActive = false;
    this.jobStatus = response.status + ': ' + response.statusText;
    this.alertClass = 'alert-danger';
    this.showErrorNotification(response.status, this.jobStatus);
  }

  private getDownloadLink(jobInfoDTO): void {
    if (jobInfoDTO.hrefEdzLink.length > 4) {
      // read links from cache entry
      this.downloads = [
        { title: jobInfoDTO.titleEdzLink, links: { file: { href: jobInfoDTO.hrefEdzLink } } },
        { title: jobInfoDTO.titlePdfLink, links: { file: { href: jobInfoDTO.hrefPdfLink } } },
      ];
    } else {
      // query links from JobServer
      this.jobService.getDownloadLink(this.actJobId, (response) => {
        if (response.isError === true) {
          this.handleError(response);
        } else {
          this.downloads = response;
        }
      });
    }
  }

  private handleResponse(jobInfoDTO): void {
    if (jobInfoDTO.isError === true) {
      // in case if http level errors
      this.handleError(jobInfoDTO);
    } else {
      this.jobActive = true;
      this.actJobId = jobInfoDTO.jobId;
      this.jobStatus = jobInfoDTO.status;

      if (!this.jobStatus.startsWith('Finished')) {
        this.showInfoNotification(this.jobStatus);
      }

      if (this.jobStatus === DEFINITIONS.jobState_Successful) {
        this.alertClass = 'alert-success';
        this.jobSuccess = true;
        this.getDownloadLink(jobInfoDTO);
        this.jobActive = false;
        this.jobStatus = '_GenerateSuccess';
        this.showSuccessNotification(DEFINITIONS.jobState_Successful);
      } else if (this.jobStatus.startsWith(DEFINITIONS.jobState_Error)) {
        this.alertClass = 'alert-danger';
        this.jobSuccess = false;
        this.jobActive = false;
        this.jobError = true;
        this.jobStatus = (jobInfoDTO.status === DEFINITIONS.jobState_Error_TypeCode) ? '_TypeCodeInvalid' : '_GenerateError';

        // notification only if component is visible
        this.showErrorNotification(undefined, 'FinishedInError');
      }
    }
  }

  public getTypeCodeInputPart(fullTypeCode): string {
    if (this.typeCodeRootPart) {
      return (fullTypeCode.toUpperCase().replace(this.typeCodeRootPart, '')).trim();
    }
    return fullTypeCode.trim();
  }

  public async onDownloadSingleFile(hrefFile: string, typeCode?: string) {
    // Check if download already runs
    if (this.downloadInProcess) {
      return;
    }
    this.disposeResultSnackBar();
    this.downloadInProcess = true;
    try {
      // download and save single file
      const response = await this.jobService.downloadSingleFile(hrefFile);
      if (response) {
        saveAs(response, hrefFile.split('/').pop());
      }
    } catch (errResponse) {
      this.logger.error('(generator.component) ERROR jobService.downloadSingleFile(..), status=' + errResponse.status + ', statusText=' + errResponse.statusText);
      if (errResponse.status === 404) {
        this.showDownloadErrorNotification(this.downloadErrorSingleFileNotFoundText);
      } else {
        this.showDownloadErrorNotification(errResponse.message);
      }
    }
    // unlock download
    this.downloadInProcess = false;
  }

  private showInfoNotification(jobStatus: string): void {
    const message = this.generationInWorkText + ' (' + jobStatus + ')';
    if (this.compomentIsVisible) {
      // show or update notification
      if (!this.matInfoSnackBarRef || this.matInfoSnackBarRef.containerInstance.snackBarConfig.data.message.indexOf(jobStatus) === -1) {
        this.matInfoSnackBarRef = this.messageService.toast( ToastTypes.Information, message);
        this.matInfoSnackBarRef.containerInstance.snackBarConfig.duration = 6000000;   //set toast duration to 100min
        this.resultNotificationEnabled = true;
      }
    }
  }

  private showSuccessNotification(jobStatus: string): void {
    const message = this.generateSuccessText;
    if (this.compomentIsVisible && this.resultNotificationEnabled) {
      this.messageService.toast( ToastTypes.Success, message);
      this.resultNotificationEnabled = false;
      this.disposeInfoSnackBar();
    }
  }

  private showErrorNotification(httpRespStatus: number, jobStatus: string): void {
    let message = (httpRespStatus === 400) ? this.typeCodeErrorInvalidText : this.generateErrorText;
    if (this.compomentIsVisible && this.resultNotificationEnabled) {
      if (jobStatus !== 'FinishedInError' && httpRespStatus !== 400) {
        message = message + ' (' + jobStatus + ').';
      }
      this.messageService.toast( ToastTypes.Error, message);
      this.resultNotificationEnabled = false;
      this.disposeInfoSnackBar();
    }
  }

  private showDownloadErrorNotification(errorText: string): void {
    const message = this.downloadErrorText  + ' (' + errorText + ')';
    if (this.compomentIsVisible) {
      this.matResultSnackBarRef = this.messageService.toast( ToastTypes.Error, message);
      this.disposeInfoSnackBar();
    }
  }

  private disposeInfoSnackBar() {
    if (this.matInfoSnackBarRef) {
      this.matInfoSnackBarRef.dismiss();
      this.matInfoSnackBarRef = undefined;
    }
  }

  private disposeResultSnackBar() {
    if (this.matResultSnackBarRef) {
      this.matResultSnackBarRef.dismiss();
      this.matResultSnackBarRef = undefined;
    }
  }

  private getGenModeQueryString() {
    // get generator running mode
    let genmode = sessionStorage.getItem(DEFINITIONS.sessParGenMode);
    let eplanversion = sessionStorage.getItem(DEFINITIONS.sessParEplanVersion);
    genmode = (eplanversion) ? (genmode + ',' + eplanversion) : genmode

    // encode ? to enable EPLAN query param handling 
    return ("%3Fgenmode=" + genmode);
  }

  public getImportLink(): string {
    if (this.downloads[0]) {
      return 'https://eplandata.de/portal/portal.php?action=api-importPartFromUrl&url=' + this.downloads[0].links.file.href + this.getGenModeQueryString();
    } else {
      return;
    }
  }

  public getMakroLink(): string {
    if (this.downloads[0]) {
      const title = this.downloads[0].title;
      const partNr = title.startsWith('R9') ? title.substring(0, title.lastIndexOf('.')) : this.typeCodeComplete;
      return 'https://eplandata.de/portal/portal.php?action=api-placeDeviceFromUrl&url=' + this.downloads[0].links.file.href + this.getGenModeQueryString() +
             '&partnr=BOS.' + partNr;
    } else {
      return;
    }
  }

  public sendSupportRequest(): void {
    window.open('mailto:ECAD-Configuration.Support@boschrexroth.de?subject=' +
                '[ECAD-Configuration] Support Request (Typecode: ' + this.typeCodeComplete + ')', '_blank');
  }

  public get resultNotificationEnabled(): boolean {
    if (sessionStorage.getItem('ecad.Generator.resultNotificationEnabled') === 'true') {
      return true;
    } else {
      return false;
    }
  }
  public set resultNotificationEnabled(value: boolean) {
    sessionStorage.setItem('ecad.Generator.resultNotificationEnabled', value.toString());
  }

  public isBtnGenerateDisabled(): boolean {
    //disable if input field is empty or job is active
    if( !this.typeCodeInputPart || this.typeCodeInputPart==='' || this.jobActive===true) {
      return true;
    }
    //disable if error field validation
    if(this.userTypeIdInputPartVar && this.userTypeIdInputPartVar.hasError('typeCodeMatNumberValidator')){
      return true;
    } 
    //disable if input not equal to first Autocomplete item
    const autoList = this.autocompTypeListCurrent;
    if( !this.autocompleteTypeCodeMode && autoList.length > 0 && autoList[0].materialnumber !== this.typeCodeInputPart) {
      return true;
    }

    return false;
  }

  // *****************************************************************************************
  //
  // AUTOCOMPLETE SECTION
  //
  // *****************************************************************************************
  initAutoComplete() {
    let searchValue = '';

    this.typeCodeInputPart$
    .pipe(
      debounceTime(200),
      tap((value: string) => {
        // set autocompleteMode and search value
        this.autocompleteTypeCodeMode = value.startsWith('R9') ? false : true;
        searchValue = value;
      }),
      switchMap(value =>
        this.productVariantService.getVariants( this.moduleData, searchValue)
      )
    )
    .subscribe(
      (productVariants: IProductVariant[]) => {
      this.setPlaceholder(productVariants);
      this.autocompTypeListCurrent = productVariants;
      this.autocompTypeList$ = of<ProductVariant[]>(productVariants);

      //do field validation
      if(!this.isTypeCodeInputValid()) {
        this.userTypeIdInputPartVar.control.setErrors({ typeCodeMatNumberValidator: { valid: false }});
        this.logger.trace('(generator.component) ## SET validation Error, new value => ' + JSON.stringify(this.userTypeIdInputPartVar.errors));
      } else {
        this.userTypeIdInputPartVar.control.setErrors(null);
        this.logger.trace('(generator.component) ## CLEAR validation Error, new value => ' + JSON.stringify(this.userTypeIdInputPartVar.errors));
      }
    },
    (error) => {
      this.logger.error('(generator.component) Error initAutoComplete() => ' + JSON.stringify(error));
      this.setPlaceholder(undefined);
    });
  }

  private isTypeCodeInputValid(): boolean {
    //material number has empty autocomplete list
    if(!this.autocompleteTypeCodeMode &&  this.autocompTypeListCurrent.length === 0) {
      return false;
    } 
    //material number doesn't fit to first autocomplete list item
    if(!this.autocompleteTypeCodeMode &&  
        this.autocompTypeListCurrent.length >= 1 &&  
        this.autocompTypeListCurrent[0].materialnumber !== this.typeCodeInputPart) {
      return false;
    }
    return true;
  }

  private setPlaceholder(variantsList: ProductVariant[]) {
    if (variantsList && variantsList.length > 0) {
      this.typeCodePlaceholder = this.typeCodePlaceholderAutocompleteText;
    } else {
      this.typeCodePlaceholder = this.typeCodePlaceholderText;
    }
  }

  public onOptionSelected(option) {
    // typecode an materialnumber settings
    this.typeCodeInputPart = this.getTypeCodeInputPart(option.typecode);
    if( !this.materialNumber || (this.typeCodeComplete !== option.typecode.toUpperCase())) {
      this.typeCodeComplete = option.typecode.toUpperCase();
      this.typeCodeForJobServerRequest = this.typeCodeComplete.replace(/\//g, '%2F');
      this.materialNumber = option.materialnumber;
    }
    // clear validation error
    if(this.userTypeIdInputPartVar) {
      this.userTypeIdInputPartVar.control.setErrors(null);
    }

    // handle autocomplete
    if (this.typeCodeInputPart$.observers) {
      this.typeCodeInputPart$.next(this.typeCodeInputPart);
    }
  }

  public syncDeviceDataWithAutocompleteData() {
    if (this.typeCodeInputPart.startsWith('R91')) {
      // Input: material number
      this.setDeviceDataByMatNumber(this.typeCodeInputPart);
    } else {
      // Input: typecode
      this.setMaterialNumberByTypecode(this.typeCodeComplete);
    }
  }

  public setDeviceDataByMatNumber(matnumber) {
    if( this.autocompTypeListCurrent) {
      for (const variant of this.autocompTypeListCurrent) {
        if (matnumber.toUpperCase() === variant.materialnumber) {
          this.typeCodeInputPart = this.getTypeCodeInputPart(variant.typecode);
          this.materialNumber = variant.materialnumber;

          // reload autocomplete
          if (this.typeCodeInputPart$.observers) {
            this.typeCodeInputPart$.next(this.typeCodeInputPart);
          }
        }
      }
    }
  }

  public setMaterialNumberByTypecode(typecode) {
    // check input
    if(!typecode || this.materialNumber.startsWith('R9')) {
      return;
    }
    // try to pick materialnumber from session storage (generator page)
    if (typecode.toUpperCase() === sessionStorage.getItem('ecad.generator.TypeCode')) {
      const storedMaterialNumber = sessionStorage.getItem('ecad.generator.MaterialNumber')
      if(storedMaterialNumber) {
        this.materialNumber = storedMaterialNumber;
        return;
      }
    }
    // try to pick materialnumber from session storage (overview page)
    if (typecode.toUpperCase() === sessionStorage.getItem('ecad.Category.ByTypeCode.TypeCode')) {
      const storedMaterialNumber = sessionStorage.getItem('ecad.Category.ByTypeCode.MaterialNumber')
      if(storedMaterialNumber) {
        this.materialNumber = storedMaterialNumber;
        return;
      }
    }
    // try to pick materialnumber from autocompleteListCurrent
    if( this.autocompTypeListCurrent) {
      for (const variant of this.autocompTypeListCurrent) {
        if (typecode.toUpperCase() === variant.typecode) {
          if(variant.materialnumber) {
            this.materialNumber = variant.materialnumber;
            break;
          }
        }
      }
    }
  }
}


// *****************************************************************************************
// Forces change event for typecode/materialnumber input validation
// *****************************************************************************************
export class MyErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(control: UntypedFormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    const isSubmitted = form && form.submitted;
    return !!(control && control.invalid);
  }
}